<template>
    <div class="main-container">
        <div class="title">Ingresa a los juegos de inteligencia cramerianos</div>
        <div class="login-container">
            <img 
                src="../assets/escudo-cramer.png" 
                alt="escudo cramer" 
                class="logo-login mb-5"
            >
            <form class="form-login" @submit.prevent="login()" >
                <div class="form__input-label-container mt-5">
                    <label for="user">Correo</label>
                    <input v-model="user" type="email" id="user" name="user">
                </div>
                <div class="form__input-label-container mt-5">
                    <label for="password">Constraseña</label>
                    <input v-model="password" type="password" id="password" name="password">
                </div>
                <div v-if="errorMessage" class="error-msg">
                    {{errorMessage}}
                </div>
                <button class="login-button mt-5" type="submit">Login</button>
                 <p>
                        <router-link to="/reset" class="btn-register">
                                ¿Haz olvidado tu contraseña?
                        </router-link>
                 </p>
            </form>
            <div class="login__register-message">
                <p>¿Aún no tienes una cuenta? crea una 
                    <router-link to="/registrar" class="btn-register">
                            aquí
                    </router-link>
                </p>
            </div>
        </div>
        <img class="login-lion" src="../assets/characters/teacher-cramer.png">
    </div>
</template>
<script>
import {auth} from '../firebase'
export default {
    data:()=>({
        user:'',
        password:'',
        errorMessage:''
    }),
    methods:{
        login(){
            auth.signInWithEmailAndPassword(this.user, this.password)
            .then(() => {
                // Signed in
                // ...
                //console.log(user.user.uid)
                this.$router.replace('/home')
            })
            .catch((error) => {
                let errorCode = error.code;
                //let errorMessage = error.message;
                if(errorCode=='auth/wrong-password')
                {
                    this.errorMessage='La contraseña es incorrecta'
                }
                else if(errorCode=="auth/user-not-found")
                {
                    this.errorMessage="El usuario no existe"
                }
                else if(errorCode=="auth/network-request-failed")
                {
                    this.errorMessage="Tienen problemas de conexión :c"
                }
                else{
                    this.errorMessage="Ocurrió un error inesperado"
                }
                //console.log(errorCode)
            });
        }
    }
}
</script>
<style scoped>
.title{
    display:none;
}
.login-lion{
    display: none;
}
.login-container{
    background-color:#050259;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/bg-movil/bg-login.svg');
    background-repeat: no-repeat;
    background-position: center;
}
.logo-login{
    width: 40vh;
}
.form-login{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.form__input-label-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.form__input-label-container label{
    color: white;
    font-size: 25px;
}
.form__input-label-container input{
    border-radius:5px;
    border:0px;
    outline: none;
    box-shadow: none;
    width: 99%;
    height: 2.5em;
}
.login-button{
    background-color:#F23827;
    border: white solid 1px;
    color:white;
    font-family: 'Teko', sans-serif;
    font-size: 30px;
    width: 100%;
    border-radius:10px;
}
.mt-5{
    margin-top:20px;
}
.mb-5{
    margin-bottom: 20px;
}
.login-button:hover{
    cursor: pointer;
}
input:focus, input.form-control:focus,button {

    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
.login__register-message{
    margin-top:2em;
    color:white;
}
.btn-register{
    color:aqua;
}
.error-msg{
    margin-top:1em;
    color:tomato;
}
@media (min-width: 900px) and (orientation: landscape) {
    .login-container{
        background-color: #050259;
        box-shadow: white 3px 3px 10px;
    }
    .main-container{
        display: grid;
        grid-template-columns: 10% 20% 20% 10% 20% 20%;
        grid-template-rows: 10vh 80vh 10vh;
        background-color: #050259;
        background-image: url('../assets/bg-desktop/bg-login.svg');
        background-position-x: -10px;
        background-position-y: -500px;
    }
    .login-lion{
        display: block;
        width: 70vh;
        transform: translateY(10%);
        grid-column: 4 / 4;
        grid-row: 2 / 2;
    }
    .login-container{
        height: 80vh;
        grid-column: 2 / 2;
        grid-row:2 / 2;
    }
    .logo-login{
        width: 30vh;
    }
    .title{
        text-align: center;
        display: block;
        font-size: 3em;
        grid-column: 3 / 7;
        transform: translateY(40%);
        color:white;
    }
}
</style>